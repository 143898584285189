
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Countdown from "vue3-countdown";
import CustomAudio from "@/components/CustomAudio.vue";
import ChoiceQuestion from "@/components/ChoiceQuestion.vue";
import MultipleQuestion from "@/components/MultipleQuestion.vue";
import TrueOrFalseQuestion from "@/components/TrueOrFalseQuestion.vue";
import SheetMusicCreation from "@/components/SheetMusicCreation.vue";
import SheetMusicSing from "@/components/SheetMusicSing.vue";
import SheetMusicSong from "@/components/SheetMusicSong.vue";
import BriefAnswerQuestion from "@/components/BriefAnswerQuestion.vue";
import FillsInQuestion from "@/components/FillsInQuestion.vue";
import ConnectQuestion from "@/components/ConnectQuestion.vue";
import NestingQuestion from "@/components/NestingQuestion.vue";
import JigsawQuestion from "@/components/JigsawQuestion.vue";
import DragQuestion from "@/components/DragQuestion.vue";
import ColorWheelQuestion from "@/components/ColorWheelQuestion.vue";
import { startExam, saveExamAnswer } from "@/services";
import useExam from "@/hooks/useExam";
import { useStore } from "@/store";
import { MutationNames } from "@/store/index";
import _ from "lodash";
import moment from "moment";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { onBeforeRouteLeave } from "vue-router";
import { checkLockFree, lock, unLock } from "@/utils/liner-lock";
import { platform as platformService } from "@/services/platform";
import { Unlock } from "@element-plus/icons-vue/dist/types";
import VolumeSetting from "@/components/VolumeSetting.vue";
export default defineComponent({
  components: {
    Countdown,
    CustomAudio,
    ChoiceQuestion,
    MultipleQuestion,
    TrueOrFalseQuestion,
    SheetMusicCreation,
    SheetMusicSing,
    SheetMusicSong,
    BriefAnswerQuestion,
    FillsInQuestion,
    ConnectQuestion,
    NestingQuestion,
    JigsawQuestion,
    DragQuestion,
    ColorWheelQuestion,
    VolumeSetting,
  },
  setup() {
    const countdownRef = ref<any>({});
    const stopTime = () => {
      countdownRef.value.stop();
    };
    let examEnd = ref(false); // 标志位标志考试时候结束 false|未结束；true｜结束
    let saveAnswerEnd = ref(true); // 标识位提交答案是否结束 true｜结束；false｜未结束
    const questionRef: any = ref<null | HTMLElement>(null);
    let loading: any = ref("");
    let paperLoading = ref(false);
    let paperText = ref("交卷中...");
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let question: any = ref({}); // 试题信息
    let examInfo: any = ref({}); // 考试信息
    let paperList: any = ref([]); // 试卷列表信息
    let borderBottomList: any = ref([]); // 最后一行试卷下边框
    let paperIndex: any = ref(0); // 当前选中的试卷
    let borderBottomRightRadius: any = ref(); // 试卷左下角边框
    let borderBottomLeftRadius: any = ref(); // 试卷右下角边框
    let alias: any = ref([]); // 试卷信息
    let indexInPart: any = ref(1); // 题目大题序号
    let indexInQuestion: any = ref(1); // 题目小题序号
    let btnNextShow = ref(true);
    let nextBtnStyle = ref({ visibility: "visible" });
    let btnPreviousShow = ref(false);
    let time = ref(0); // 距离考试结束剩余时间
    let PLATFORM = ref(0); // 当前环境 1｜Android 0｜pc
    const name = ref(store.state.name);
    const grade = ref(store.state.grade);
    const classNumber = ref(store.state.classNumber);
    const examType = route.query.examType; // 当前试卷状态 histroy:历史记录
    console.log("examType", examType);
    let questionDisable = ref(true); // 题目能否编辑状态 true:不可编辑｜｜false:可以编辑
    const countdownShow = ref(true);
    let finishNum = ref(0);
    let finishTimeout: any = ref(0);
    const { submitPapers: _submitPapers } = useExam();

    watch(
      () => store.state.papers,
      (newValue, oldValue) => {
        paperList.value = newValue;
        alias.value = (store.state.papers as any)[
          paperIndex.value
        ].parperParts.parts;
      }
    );

    onBeforeRouteLeave((to, from) => {
      countdownShow.value = false;
    });

    const handleFinish = () => {
      finishTimeout.value = setTimeout(() => {
        finishNum.value++;
        if (finishNum.value === 180) {
          // loading.value && loading.value.close();
          paperLoading.value = false;
          ElMessageBox.close();
          console.log("自动交卷失败框");
          ElMessageBox.confirm(
            "网络异常，交卷失败，请联系监考老师",
            "网络异常",
            {
              confirmButtonText: "退出",
              type: "warning",
              center: true,
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              customClass: "exam-score-message-box",
            }
          ).then(() => {
            // loading.value && loading.value.close();
            paperLoading.value = false;
            unLock();
            store.commit(MutationNames.CHANGE_EXAMERROE, false);
            store.commit(MutationNames.CHANGE_EXAMEND, false);
            let isAndroid = true;
            const device = navigator.userAgent;
            isAndroid =
              device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
            if (isAndroid) {
              console.log("catch通知android");
              platformService.goLogin();
            } else {
              console.log("catch通知pc");
              router.push({
                path: "/login",
              });
            }
          });
          return;
        }
        console.log("自动提交第", finishNum.value);
        console.log("saveAnswerEnd", saveAnswerEnd.value);
        console.log("countdownShow.value", countdownShow.value);
        if (saveAnswerEnd.value) {
          if (countdownShow.value) {
            console.log("开始交卷");
            // loading.value && loading.value.close();
            paperLoading.value = false;
            paperText.value = "交卷中...";
            paperLoading.value = true;
            // loading.value = ElLoading.service({
            //   lock: true,
            //   text: "交卷中...",
            //   background: "rgba(0, 0, 0, 0.3)",
            // });
            // loading.value && loading.value.close();
            paperLoading.value = false;
            submitPapers(true);
            return;
          }
        } else {
          handleFinish();
        }
      }, 1000 * 2);
    };

    const goBack = () => {
      router.back();
    };
    const saveOldQuestionAnswer = async () => {
      saveAnswerEnd.value = false;
      if (examType === "histroy") {
        return;
      }
      console.log("12qwww");
      paperText.value = "处理中...";
      paperLoading.value = true;
      // loading.value && loading.value.close();
      // loading.value = ElLoading.service({
      //   lock: true,
      //   text: "处理中...",
      //   background: "rgba(0, 0, 0, 0.3)",
      // });
      // try {
      const oldQuestion = _.cloneDeep(
        alias.value[indexInPart.value - 1].questions[indexInQuestion.value - 1]
      );
      // const AnswerInfo = await questionRef.value.getAnswer();
      let AnswerInfo = {};
      try {
        AnswerInfo = await questionRef.value.getAnswer();
      } catch (error) {
        console.warn("getAnsweross", error);
        // loading.value && loading.value.close();
        paperLoading.value = false;
        unLock();
        store.commit(MutationNames.CHANGE_EXAMERROE, true);
        clearTimeout(finishTimeout.value);
        ElMessageBox.close();
        ElMessageBox.confirm("网络异常，请联系监考老师", "网络异常", {
          confirmButtonText: "退出",
          type: "warning",
          center: true,
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          customClass: "exam-score-message-box",
        }).then(() => {
          clearTimeout(finishTimeout.value);
          // loading.value && loading.value.close();
          paperLoading.value = false;
          unLock();
          store.commit(MutationNames.CHANGE_EXAMERROE, false);
          store.commit(MutationNames.CHANGE_EXAMEND, false);
          let isAndroid = true;
          const device = navigator.userAgent;
          isAndroid =
            device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
          if (isAndroid) {
            console.log("catch通知android");
            platformService.goLogin();
          } else {
            console.log("catch通知pc");
            router.push({
              path: "/login",
            });
          }
          // loading.value && loading.value.close();
          paperLoading.value = false;
        });
        throw error;
      }
      console.log("AnswerInfo", AnswerInfo);
      await changePartsAnswer(AnswerInfo);
      // loading.value && loading.value.close();
      paperLoading.value = false;

      saveAnswerEnd.value = true;
      // } catch (e) {
      //   console.error(e);
      //   ElMessage({
      //     type: "warning",
      //     offset: 100,
      //     message: "保存答案失败，请重试！",
      //     center: true,
      //   });
      //   // unLock();
      // } finally {
      //   loading.value && loading.value.close();
      // }
    };
    const changePartsAnswer = async (params: any) => {
      const { type, answer_params = {} } = params || {};
      if (type) {
        const { parentId, questionId, answer } = answer_params;
        if (!parentId) {
          // * 正常体型的保存答案
          const nowQuestion =
            alias.value[indexInPart.value - 1].questions[
              indexInQuestion.value - 1
            ];
          const nowAlias = Object.assign(nowQuestion, {
            answer_params: params.answer_params,
          });
          alias.value[indexInPart.value - 1].questions[
            indexInQuestion.value - 1
          ] = nowAlias;
          const question =
            alias.value[indexInPart.value - 1].questions[
              indexInQuestion.value - 1
            ];
          await saveAnswer(question);
        } else {
          // * 套题题型
          const parentQuestion =
            alias.value[indexInPart.value - 1].questions.find(
              (question: any) => question.id == parentId
            ) || {};
          const { sub_questions = [] } = parentQuestion;
          const sub_question = sub_questions.find(
            (subQuestion: any) => subQuestion.id == questionId
          );
          // merge方法对undefined的值会跳过
          if (answer.answer_params) {
            _.merge(sub_question, { answer_params: answer.answer_params });
          } else {
            sub_question.answer_params = answer.answer_params;
          }
          await saveAnswer(sub_question);
        }
      }
    };

    const goToPaper = _.throttle(async (id: any) => {
      console.log(111111);
      lock();
      await saveOldQuestionAnswer();
      // if (!examEnd.value) {
      if (!store.state.examEnd) {
        paperIndex.value = id;
        indexInPart.value = 1;
        indexInQuestion.value = 1;
        const inPartNum = alias.value.length; // 当前试卷总大题数
        const inQuestionNum =
          alias.value[indexInPart.value - 1].questions.length; // 当前试卷大题下总小题数
        if (
          inPartNum > indexInPart.value ||
          inQuestionNum > indexInQuestion.value
        ) {
          btnNextShow.value = true;
          nextBtnStyle.value = { visibility: "visible" };
        }
        // btnPreviousShow.value = false;
        alias.value = (store.state.papers as any)[id].parperParts.parts;
        question.value =
          alias.value[indexInPart.value - 1].questions[
            indexInQuestion.value - 1
          ];
        question.value.indexInPart = indexInPart.value;
        question.value.indexInQuestion = indexInQuestion.value;
        if (
          indexInPart.value === 1 &&
          indexInQuestion.value === 1 &&
          paperIndex.value == 0
        ) {
          btnPreviousShow.value = false;
        } else {
          btnPreviousShow.value = true;
        }
        // 更新最新已答题目数
        const newestExamInfo = store.getters.allPaperQuestionsStatistics;
        paperList.value = newestExamInfo;
      } else {
        return;
      }
    }, 800);

    const saveAnswer = async (question: any) => {
      // todo: 需要保证每个题型组件独立判断是否跟旧答案一致
      // const nowQuestion =
      //   alias.value[indexInPart.value - 1].questions[indexInQuestion.value - 1];
      // const oldAnswerParams = JSON.stringify(oldQuestion.answer_params);
      // const nowAnswerParams = JSON.stringify(nowQuestion.answer_params);
      // // if (nowQuestion.answer_params === undefined) {
      // if (oldAnswerParams === nowAnswerParams) {
      //   loading.value.close();
      //   console.log("不用提交答案");
      // } else {

      // }
      console.log("question", question);
      let answer: any = [];
      if (question.answer_params?.aiScore !== undefined) {
        answer = [
          {
            paper_id: paperList.value[paperIndex.value].id,
            question_id: question.id,
            ai_raw_score: question.answer_params.aiScore,
            answer_params:
              question.answer_params === undefined
                ? {}
                : question.answer_params,
          },
        ];
        console.log("ss", answer);
      } else {
        answer = [
          {
            paper_id: paperList.value[paperIndex.value].id,
            question_id: question.id,
            answer_params:
              question.answer_params === undefined
                ? {}
                : question.answer_params,
          },
        ];
        console.log("ssr", answer);
      }
      console.log("ssdd", answer);
      let saveExamAnswerInfo: any = {};
      saveExamAnswerInfo.create_device_mac = store.state.MAC || "abcdefg";
      saveExamAnswerInfo.create_device_type = PLATFORM.value;
      saveExamAnswerInfo.answers = answer.map((i: any) => {
        if (i.answer_params && Object.keys(i.answer_params).length > 0) {
          i.answer_params.update_time = new Date().getTime();
        }
        i.paper_name = store.state.papers?.filter(
          (j) => j.id === i.paper_id
        )[0].name;
        return i;
      });
      saveExamAnswerInfo.exam_id = examInfo.value.id;
      saveExamAnswerInfo.student_name = store.state.name;
      saveExamAnswerInfo.student_id = store.state.studentId;
      saveExamAnswerInfo.ip = store.state.examIp;
      let retryCount = 0;
      if (retryCount > 2) {
        // 弹框提示
        ElMessageBox.close();
        clearTimeout(finishTimeout.value);
        ElMessageBox.confirm("网络异常，请联系监考老师", "网络异常", {
          confirmButtonText: "退出",
          type: "warning",
          center: true,
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          customClass: "exam-score-message-box",
        }).then(() => {
          clearTimeout(finishTimeout.value);
          store.commit(MutationNames.CHANGE_EXAMERROE, false);
          store.commit(MutationNames.CHANGE_EXAMEND, false);
          let isAndroid = true;
          const device = navigator.userAgent;
          isAndroid =
            device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
          if (isAndroid) {
            console.log("catch通知android");
            platformService.goLogin();
          } else {
            console.log("catch通知pc");
            router.push({
              path: "/login",
            });
          }
        });
        return;
      } else {
        retryCount++;
        setTimeout(async () => {
          if (!retryCount) {
            return;
          }
          await saveExamAnswer(examInfo.value.id, saveExamAnswerInfo);
          console.log("重试saveExamAnswer次数--》", retryCount);
        }, 1000);
      }
      // await saveExamAnswer(examInfo.value.id, saveExamAnswerInfo);
    };

    // 选中的题目
    // const handlePartOrQuestion = (indexPart: number, indexQuestion: number) => {
    const handlePartOrQuestion = _.throttle(
      async (indexPart: number, indexQuestion: number) => {
        lock();
        try {
          await saveOldQuestionAnswer();
        } catch (error) {
          unLock();
          return;
          // throw error;
        }

        // 选择的是当前题目，就不执行下面的步骤
        if (
          indexPart + 1 === indexInPart.value &&
          indexQuestion + 1 === indexInQuestion.value
        ) {
          unLock();
          return;
        }

        if (!store.state.examEnd) {
          indexInPart.value = indexPart + 1;
          indexInQuestion.value = indexQuestion + 1;
          question.value = alias.value[indexPart].questions[indexQuestion];
          question.value.indexInPart = indexInPart.value;
          question.value.indexInQuestion = indexInQuestion.value;
          const inPartNum = alias.value.length; // 当前试卷总大题数
          const inQuestionNum =
            alias.value[indexInPart.value - 1].questions.length; // 当前试卷大题下总小题数
          // if (indexPart > 0 || indexQuestion > 0) {
          //   btnPreviousShow.value = true;
          // } else {
          //   btnPreviousShow.value = false;
          // }
          const isHasNextPaper =
            paperIndex.value === paperList.value.length - 1 &&
            indexInPart.value === alias.value.length &&
            indexInQuestion.value ===
              alias.value[indexInPart.value - 1].questions.length;

          console.log("sdsdsd", isHasNextPaper);
          if (
            indexInPart.value === 1 &&
            indexInQuestion.value === 1 &&
            paperIndex.value == 0
          ) {
            btnPreviousShow.value = false;
          } else {
            btnPreviousShow.value = true;
          }
          if (
            indexPart + 1 === inPartNum &&
            indexQuestion + 1 === inQuestionNum &&
            question.value.type !== 11
          ) {
            if (isHasNextPaper) {
              // 判断是不是最后一张试卷的最后一题
              btnNextShow.value = false;
              nextBtnStyle.value = { visibility: "hidden" };
            }
          } else {
            btnNextShow.value = true;
            nextBtnStyle.value = { visibility: "visible" };
          }
          // 更新最新已答题目数
          const newestExamInfo = store.getters.allPaperQuestionsStatistics;
          paperList.value = newestExamInfo;
        } else {
          unLock();
          return;
        }
      },
      800
    );

    // 下一题
    const getNext = _.throttle(async () => {
      /**
       * 下一题
        1. 普通题目
            1. 如果是最后一份试卷最后一题 -不显示下一题
            2. 点击切换题目
                1. 如果有当前试卷还没完就切换下一题
                2. 如果有下一份试卷切换下一份试卷第一题
        2. 套题题目
            1. 如果是套题最后一题，运行普通切题的逻辑
            2. 如果不是最后道题，切换下一道小题
       */

      console.log("下", store.state.examEnd);
      //* 上一题按钮必定显示
      btnPreviousShow.value = true;
      // * 判断当前题型
      const { type } = question.value;

      // 前往下一题
      const goNext = async () => {
        // 是不是最后一道题，并且还有下一份试卷
        const isHasNextPaper =
          paperIndex.value < paperList.value.length - 1 &&
          indexInPart.value === alias.value.length &&
          indexInQuestion.value ===
            alias.value[indexInPart.value - 1].questions.length;
        if (isHasNextPaper) {
          if (!store.state.examEnd) {
            // 兼容弱网情况 交卷和切换题目同时触发
            await goToPaper(paperIndex.value + 1);
          } else {
            return;
          }
        } else {
          // 切换到下一题
          await saveOldQuestionAnswer();
          if (!store.state.examEnd) {
            // 兼容弱网情况 交卷和切换题目同时触发
            const isLastPartQuestion =
              indexInQuestion.value ===
              alias.value[indexInPart.value - 1].questions.length;
            if (isLastPartQuestion) {
              // 大题的最后题 切换到下一个大题的第一题
              indexInPart.value += 1;
              indexInQuestion.value = 1;
            } else {
              indexInQuestion.value += 1;
            }
            question.value =
              alias.value[indexInPart.value - 1].questions[
                indexInQuestion.value - 1
              ];
            question.value.indexInPart = indexInPart.value;
            question.value.indexInQuestion = indexInQuestion.value;
          } else {
            return;
          }
        }
      };
      let { isLastSubQuestion, changeSubQuestion } = questionRef.value;

      // 设置锁，保证在数据渲染完成前不会被自动交卷
      lock();

      if (type !== 11) {
        // 普通题目
        await goNext();
      } else {
        // 套题题目
        if (isLastSubQuestion()) {
          await goNext();
          isLastSubQuestion = questionRef.value.isLastSubQuestion;
        } else {
          await saveOldQuestionAnswer();
          if (!store.state.examEnd) {
            changeSubQuestion("next");
          } else {
            return;
          }
        }
      }
      const isExamLastQuestion =
        paperList.value.length - 1 === paperIndex.value &&
        indexInPart.value === alias.value.length &&
        indexInQuestion.value ===
          alias.value[indexInPart.value - 1].questions.length;
      if (
        (isExamLastQuestion && question.value.type !== 11) ||
        (isExamLastQuestion &&
          question.value.type === 11 &&
          isLastSubQuestion &&
          isLastSubQuestion())
      ) {
        // 试卷最后一道题 下一题按钮必定隐藏
        btnNextShow.value = false;
        nextBtnStyle.value = { visibility: "hidden" };
      }
      // 自动定位到题目位置
      const elemId = "question" + question.value.id;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView();
      }
      // 更新最新已答题目数
      const newestExamInfo = store.getters.allPaperQuestionsStatistics;
      paperList.value = newestExamInfo;
    });

    // 上一题
    const getPrevious = _.throttle(async () => {
      /** 上一题
        1. 普通题目
            1. 如果是第一份试卷第一题 -不显示上题
            2. 点击切换题目
                1. 如果有当前试卷还没完就切换上一题
                2. 如果有多份试卷，切换到上一份试卷的最后一道题
        2. 套题题目
            1. 如果是套题第一道题，运行普通切题的逻辑
            2. 如果不是第一道题，切换上一道小题
      */
      //* 下一题按钮必定显示
      btnNextShow.value = true;
      nextBtnStyle.value = { visibility: "visible" };
      // * 判断当前题型
      const { type } = question.value;
      // 前往上一题
      const goPrevious = async () => {
        // 是不是第一题，并且还有上份试卷
        const isHasPreviousPaper =
          indexInPart.value === 1 &&
          indexInQuestion.value === 1 &&
          paperList.value.length > 1 &&
          paperIndex.value !== 0;
        if (isHasPreviousPaper) {
          if (!store.state.examEnd) {
            await goToPaper(paperIndex.value - 1);
          } else {
            return;
          }
        } else {
          // 切换到上一题
          await saveOldQuestionAnswer();
          if (!store.state.examEnd) {
            const isFirstPartQuestion = indexInQuestion.value === 1;
            if (isFirstPartQuestion) {
              // 大题的第一题 切换到上一个大题的最后一题
              indexInPart.value -= 1;
              indexInQuestion.value =
                alias.value[indexInPart.value - 1].questions.length;
            } else {
              indexInQuestion.value -= 1;
            }
            question.value =
              alias.value[indexInPart.value - 1].questions[
                indexInQuestion.value - 1
              ];
            question.value.indexInPart = indexInPart.value;
            question.value.indexInQuestion = indexInQuestion.value;
          } else {
            return;
          }
        }
      };
      const { isFirstSubQuestion, changeSubQuestion } = questionRef.value;
      lock();
      if (type !== 11) {
        // 普通题目
        await goPrevious();
      } else {
        // 套题题目
        if (isFirstSubQuestion()) {
          await goPrevious();
        } else {
          await saveOldQuestionAnswer();
          if (!store.state.examEnd) {
            changeSubQuestion("previous");
          } else {
            return;
          }
        }
      }

      const isExamFirstQuestion =
        paperIndex.value === 0 &&
        indexInPart.value === 1 &&
        indexInQuestion.value === 1;
      if (
        (isExamFirstQuestion && question.value.type !== 11) ||
        (isExamFirstQuestion &&
          question.value.type === 11 &&
          isFirstSubQuestion &&
          isFirstSubQuestion())
      ) {
        // 试卷第一道题 上一题按钮必定隐藏
        btnPreviousShow.value = false;
      }
      // 自动定位到题目位置
      const elemId = "question" + question.value.id;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView();
      }
      // 更新最新已答题目数
      const newestExamInfo = store.getters.allPaperQuestionsStatistics;
      paperList.value = newestExamInfo;
    });

    const isHasAnswer = (question: any) => {
      if (examType === "histroy") {
        // correctness 0|错，1｜对，2｜部分正确
        if (question.type !== 11) {
          if (question.correctness === 1) {
            return "#1ac36e";
          } else {
            return "#E14339";
          }
        } else {
          const subAnswerLen = question.sub_questions.filter(
            (sub: any) => sub.correctness === 1
          ).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#E14339";
          } else {
            return "#1ac36e";
          }
        }
      } else {
        if (question.type !== 11) {
          if (!question.answer_params) {
            return "#dff1e4";
          } else {
            return "#1ac36e";
          }
        } else {
          const subAnswerLen = question.sub_questions
            .map((sub: any) => sub.answer_params)
            .filter(Boolean).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#dff1e4";
          } else {
            return "#1ac36e";
          }
        }
      }
    };
    const isHasAnswerColor = (question: any) => {
      if (examType === "histroy") {
        return "#f7f7f7";
      } else {
        if (question.type !== 11) {
          if (!question.answer_params) {
            return "#1ac36e";
          } else {
            return "#dff1e4";
          }
        } else {
          const subAnswerLen = question.sub_questions
            .map((sub: any) => sub.answer_params)
            .filter(Boolean).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#1ac36e";
          } else {
            return "#dff1e4";
          }
        }
      }
    };

    onBeforeMount(() => {
      store.commit(MutationNames.CHANGE_EXAMERROE, false);
      store.commit(MutationNames.CHANGE_EXAMEND, false);
      saveAnswerEnd.value = true;
      console.log("examInfo", store.state.examInfo);
      const papers = store.getters.allPaperQuestionsStatistics;
      store.commit(MutationNames.CHANGE_PAPERS, papers);
      examInfo.value = store.state.examInfo?.active_exam;
      // examInfo.value = "考试";
      paperList.value = store.state.papers;
      if (
        paperList.value.length <= 1 &&
        paperList.value[0].parperParts.parts.length <= 1 &&
        paperList.value[0].parperParts.parts[0].questions.length <= 1
      ) {
        // 判断是否是套题
        if (paperList.value[0].parperParts.parts[0].questions[0].type !== 11) {
          btnNextShow.value = false;
          nextBtnStyle.value = { visibility: "hidden" };
        } else {
          if (
            paperList.value[0].parperParts.parts[0].questions[0].sub_questions
              .length <= 1
          ) {
            btnNextShow.value = false;
            nextBtnStyle.value = { visibility: "hidden" };
          }
        }
      }
      alias.value = (store.state.papers as any)[
        paperIndex.value
      ].parperParts.parts;
      question.value =
        alias.value[indexInPart.value - 1].questions[indexInQuestion.value - 1];
      question.value.indexInPart = indexInPart.value;
      question.value.indexInQuestion = indexInQuestion.value;

      let paper_ids: any = [];
      paperList.value.forEach((item: any) => {
        paper_ids.push(item.id);
        // 执行代码
      });
      // 开始考试
      const params = {
        create_device_mac: store.state.MAC || "tets",
        create_device_type: PLATFORM.value,
        paper_ids,
      };
      if (examType !== "histroy") {
        const sumTime = examInfo.value.time * 1000;
        console.log("examInfo.value", examInfo.value);
        console.log("sumTime", sumTime);
        questionDisable.value = false;
        if (store.state.examInfo?.answer_sheet === null) {
          // 第一次打开考试
          if (examInfo.value.exam_time_type === 1) {
            // 自由开始
            time.value = sumTime;
          } else {
            // 集体开始 总时长-(开始时间-当前时间)
            const inTime = moment(new Date());
            const onTime = moment(examInfo.value.start_time);
            const timeGap: any = moment.duration(
              inTime.diff(onTime, "seconds")
            );
            time.value = sumTime - timeGap * 1000;
          }

          startExam(examInfo.value.id, params);
          store.commit(MutationNames.CHANGE_ISDURINGEXAM, true);
        } else {
          // 不是第一次考试，总时长-(开始时间-当前时间)
          const inTime = moment(new Date());
          let onTime: any = "";
          if (examInfo.value.exam_time_type === 1) {
            // 自由开始，从答题开始的时候开始扣除时间
            onTime = moment(store.state.examInfo?.answer_sheet?.started_at);
          } else {
            console.log("start_time", examInfo.value);
            // 集体开始，从集体开始的时间开始扣除时间
            onTime = moment(examInfo.value.start_time);
          }
          const timeGap: any = moment.duration(inTime.diff(onTime, "seconds"));
          time.value = sumTime - timeGap * 1000;
        }
      }
    });

    const submitPapers = async (timEnd = false) => {
      console.log("交卷1", store.state.examEnd);
      console.log("examError--->", store.state.examError);
      if (!saveAnswerEnd.value) {
        // 保存答案中不能进行交卷处理
        console.log("上一次答案还未保存完成");
        return;
      }
      if (store.state.examError) {
        // 当前考试状态为失败，直接跳过下面所有操作
        console.log("保存已经出错");
        return;
      }
      // examEnd.value = true;
      store.commit(MutationNames.CHANGE_EXAMEND, true);
      console.log("交卷2", store.state.examEnd);
      if (timEnd) {
        ElMessageBox.close();
      }
      // const oldQuestion = _.cloneDeep(
      //   alias.value[indexInPart.value - 1].questions[indexInQuestion.value - 1]
      // );
      // let AnswerInfo = {};
      // AnswerInfo = await questionRef.value.getAnswer();

      // changePartsAnswer(AnswerInfo);
      // // 保存答案 不能乱放只能放这里
      // saveAnswer(oldQuestion);

      let load;
      try {
        paperText.value = "考试答案处理中...";
        paperLoading.value = true;
        // loading.value && loading.value.close();
        // load = ElLoading.service({
        //   lock: true,
        //   text: "考试答案处理中...",
        //   background: "rgba(0, 0, 0, 0.3)",
        // });
        await checkLockFree();
        // load.close();
        paperLoading.value = false;
        // 超过5分钟会自动释放锁
      } catch (e) {
        // load?.close();
        paperLoading.value = false;
        console.error(e);
      }

      console.log(2222);
      await saveOldQuestionAnswer();
      _submitPapers(store.state.MAC || "test", PLATFORM.value, timEnd, () => {
        console.log("取消交卷 回调", questionRef.value.handleCancelSubmitExam);
        questionRef.value.handleCancelSubmitExam?.();
      });
    };

    const onQuestionCompError = (error: any) => {
      store.commit(MutationNames.CHANGE_EXAMERROE, true);
      const msg = typeof error === "string" ? error : error.message;
      console.log("onQuestionCompError");
      ElMessageBox.confirm(
        `${msg}${examType !== "histroy" ? ", 返回登录页" : ""}`,
        "系统出错",
        {
          confirmButtonText: "确定",
          type: "warning",
          center: true,
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          customClass: "exam-score-message-box",
        }
      ).then(() => {
        unLock();
        // loading.value && loading.value.close();
        paperLoading.value = false;
        if (examType === "histroy") {
          return;
        }
        store.commit(MutationNames.CHANGE_EXAMERROE, false);
        store.commit(MutationNames.CHANGE_EXAMEND, false);
        let isAndroid = true;
        const device = navigator.userAgent;
        isAndroid =
          device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
        if (isAndroid) {
          console.log("catch通知android");
          platformService.goLogin();
        } else {
          console.log("catch通知pc");
          router.push({
            path: "/login",
          });
        }
      });
    };

    onMounted(() => {
      let isAndroid = true;
      const device = navigator.userAgent;
      isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (isAndroid) {
        PLATFORM.value = 1;
      }

      // 试卷切换样式控制
      if (paperList.value.length % 2 === 0) {
        borderBottomList.value = [
          paperList.value.length - 1,
          paperList.value.length - 2,
        ];
        borderBottomLeftRadius.value = paperList.value.length - 2;
        borderBottomRightRadius.value = paperList.value.length - 1;
      } else {
        borderBottomList.value = [paperList.value.length - 1];
        borderBottomLeftRadius.value = paperList.value.length - 1;
      }
      console.log("paperListqqq", paperList);
    });
    onBeforeUnmount(() => {
      console.log("unmounted!");
      if (countdownRef.value) {
        countdownRef.value.stop();
      }
      clearTimeout(finishTimeout.value);
    });
    return {
      countdownRef,
      questionRef,
      paperList,
      question,
      borderBottomList,
      paperIndex,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      alias,
      indexInPart,
      indexInQuestion,
      btnPreviousShow,
      btnNextShow,
      examInfo,
      name,
      grade,
      classNumber,
      time,
      nextBtnStyle,
      changePartsAnswer,
      goToPaper,
      handlePartOrQuestion,
      getNext,
      getPrevious,
      submitPapers,
      examType,
      goBack,
      questionDisable,
      isHasAnswer,
      countdownShow,
      handleFinish,
      isHasAnswerColor,
      onQuestionCompError,
      paperLoading,
      paperText,
    };
  },
});
